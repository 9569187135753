import service from '@/utils/request'; // @Tags EquityShop
// @Summary 创建EquityShop
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.EquityShop true "创建EquityShop"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /equityShop/createEquityShop [post]

export var createEquityShop = function createEquityShop(data) {
  return service({
    url: "/equityShop/createEquityShop",
    method: 'post',
    data: data
  });
}; // @Tags EquityShop
// @Summary 删除EquityShop
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.EquityShop true "删除EquityShop"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /equityShop/deleteEquityShop [delete]

export var deleteEquityShop = function deleteEquityShop(data) {
  return service({
    url: "/equityShop/deleteEquityShop",
    method: 'delete',
    data: data
  });
}; // @Tags EquityShop
// @Summary 删除EquityShop
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.IdsReq true "批量删除EquityShop"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /equityShop/deleteEquityShop [delete]

export var deleteEquityShopByIds = function deleteEquityShopByIds(data) {
  return service({
    url: "/equityShop/deleteEquityShopByIds",
    method: 'delete',
    data: data
  });
}; // @Tags EquityShop
// @Summary 更新EquityShop
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.EquityShop true "更新EquityShop"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"更新成功"}"
// @Router /equityShop/updateEquityShop [put]

export var updateEquityShop = function updateEquityShop(data) {
  return service({
    url: "/equityShop/updateEquityShop",
    method: 'put',
    data: data
  });
}; // @Tags EquityShop
// @Summary 用id查询EquityShop
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.EquityShop true "用id查询EquityShop"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"查询成功"}"
// @Router /equityShop/findEquityShop [get]

export var findEquityShop = function findEquityShop(params) {
  return service({
    url: "/equityShop/findEquityShop",
    method: 'get',
    params: params
  });
}; // @Tags EquityShop
// @Summary 分页获取EquityShop列表
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.PageInfo true "分页获取EquityShop列表"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /equityShop/getEquityShopList [get]

export var getEquityShopList = function getEquityShopList(params) {
  return service({
    url: "/equityShop/getEquityShopList",
    method: 'get',
    params: params
  });
};
export var getAllShopSelection = function getAllShopSelection() {
  return service({
    url: "/equityShop/getAllShopSelection",
    method: 'get'
  });
};