import service from '@/utils/request'; // @Tags EquityArtNo
// @Summary 创建EquityArtNo
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.EquityArtNo true "创建EquityArtNo"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /equityArtNo/createEquityArtNo [post]

export var createEquityArtNo = function createEquityArtNo(data) {
  return service({
    url: "/equityArtNo/createEquityArtNo",
    method: 'post',
    data: data
  });
}; // @Tags EquityArtNo
// @Summary 删除EquityArtNo
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.EquityArtNo true "删除EquityArtNo"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /equityArtNo/deleteEquityArtNo [delete]

export var deleteEquityArtNo = function deleteEquityArtNo(data) {
  return service({
    url: "/equityArtNo/deleteEquityArtNo",
    method: 'delete',
    data: data
  });
}; // @Tags EquityArtNo
// @Summary 删除EquityArtNo
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.IdsReq true "批量删除EquityArtNo"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /equityArtNo/deleteEquityArtNo [delete]

export var deleteEquityArtNoByIds = function deleteEquityArtNoByIds(data) {
  return service({
    url: "/equityArtNo/deleteEquityArtNoByIds",
    method: 'delete',
    data: data
  });
}; // @Tags EquityArtNo
// @Summary 更新EquityArtNo
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.EquityArtNo true "更新EquityArtNo"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"更新成功"}"
// @Router /equityArtNo/updateEquityArtNo [put]

export var updateEquityArtNo = function updateEquityArtNo(data) {
  return service({
    url: "/equityArtNo/updateEquityArtNo",
    method: 'put',
    data: data
  });
}; // @Tags EquityArtNo
// @Summary 用id查询EquityArtNo
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.EquityArtNo true "用id查询EquityArtNo"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"查询成功"}"
// @Router /equityArtNo/findEquityArtNo [get]

export var findEquityArtNo = function findEquityArtNo(params) {
  return service({
    url: "/equityArtNo/findEquityArtNo",
    method: 'get',
    params: params
  });
}; // @Tags EquityArtNo
// @Summary 分页获取EquityArtNo列表
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.PageInfo true "分页获取EquityArtNo列表"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /equityArtNo/getEquityArtNoList [get]

export var getEquityArtNoList = function getEquityArtNoList(params) {
  return service({
    url: "/equityArtNo/getEquityArtNoList",
    method: 'get',
    params: params
  });
};