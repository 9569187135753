var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "skuform",
          attrs: {
            model: _vm.formData,
            "label-position": "right",
            "label-width": "100px",
            rules: _vm.skuRule
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "类型:" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择", clearable: "" },
                  model: {
                    value: _vm.skuType,
                    callback: function($$v) {
                      _vm.skuType = $$v
                    },
                    expression: "skuType"
                  }
                },
                _vm._l(_vm.skuTypeOptions, function(item, key) {
                  return _c("el-option", {
                    key: key,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "标题:", prop: "name" } },
            [
              _c("el-input", {
                attrs: {
                  clearable: "",
                  placeholder: "必填",
                  maxlength: "20",
                  "show-word-limit": ""
                },
                model: {
                  value: _vm.formData.name,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "name", $$v)
                  },
                  expression: "formData.name"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "编码:", prop: "code" } },
            [
              _c("el-input", {
                attrs: {
                  clearable: "",
                  placeholder:
                    "必填,上游对商品的唯一标识或我们对自营商品的唯一标识"
                },
                model: {
                  value: _vm.formData.code,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "code", $$v)
                  },
                  expression: "formData.code"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "图片:" } },
            [
              _c(
                "el-upload",
                {
                  staticClass: "avatar-uploader",
                  attrs: {
                    action: _vm.imageAction,
                    "show-file-list": false,
                    "before-upload": _vm.imageBeforeUpload,
                    "on-success": _vm.handleAvatarSuccess
                  }
                },
                [
                  _vm.imageUrl
                    ? _c("img", {
                        staticClass: "avatar",
                        attrs: { src: _vm.imageUrl }
                      })
                    : _c("i", {
                        staticClass: "el-icon-plus avatar-uploader-icon"
                      })
                ]
              ),
              _c("span", { staticClass: "help" }, [
                _vm._v("尺寸282*175, 建议大小200kb")
              ])
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "面值:", prop: "facePrice" } },
                    [
                      _c(
                        "el-input",
                        {
                          attrs: {
                            clearable: "",
                            placeholder: "必填，最多3位小数"
                          },
                          model: {
                            value: _vm.formData.facePrice,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "facePrice", $$v)
                            },
                            expression: "formData.facePrice"
                          }
                        },
                        [_c("template", { slot: "append" }, [_vm._v("元")])],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "上游进货价:", prop: "scCostPrice" } },
                    [
                      _c(
                        "el-input",
                        {
                          attrs: {
                            clearable: "",
                            placeholder: "必填，最多3位小数"
                          },
                          model: {
                            value: _vm.formData.scCostPrice,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "scCostPrice", $$v)
                            },
                            expression: "formData.scCostPrice"
                          }
                        },
                        [_c("template", { slot: "append" }, [_vm._v("元")])],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "建议零售价:", prop: "salePrice" } },
                    [
                      _c(
                        "el-input",
                        {
                          attrs: {
                            clearable: "",
                            placeholder: "必填，最多3位小数"
                          },
                          model: {
                            value: _vm.formData.salePrice,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "salePrice", $$v)
                            },
                            expression: "formData.salePrice"
                          }
                        },
                        [_c("template", { slot: "append" }, [_vm._v("元")])],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "客户成本价:", prop: "costPrice" } },
                    [
                      _c(
                        "el-input",
                        {
                          attrs: {
                            clearable: "",
                            placeholder: "必填，最多3位小数"
                          },
                          model: {
                            value: _vm.formData.costPrice,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "costPrice", $$v)
                            },
                            expression: "formData.costPrice"
                          }
                        },
                        [_c("template", { slot: "append" }, [_vm._v("元")])],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "货号:" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    filterable: "",
                    placeholder: "请选择货号",
                    remote: "",
                    "reserve-keyword": "",
                    "remote-method": _vm.getArtNoList
                  },
                  model: {
                    value: _vm.formData.artNoId,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "artNoId", $$v)
                    },
                    expression: "formData.artNoId"
                  }
                },
                _vm._l(_vm.artNoes, function(item) {
                  return _c(
                    "el-option",
                    {
                      key: item.ID,
                      attrs: { label: item.name, value: item.ID }
                    },
                    [_c("span", [_vm._v(_vm._s(item.ID + "-" + item.name))])]
                  )
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "产品说明:" } },
            [
              _c("editor", {
                attrs: { init: _vm.editorInit },
                model: {
                  value: _vm.formData.instruction,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "instruction", $$v)
                  },
                  expression: "formData.instruction"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "使用须知:" } },
            [
              _c("editor", {
                attrs: { init: _vm.editorInit },
                model: {
                  value: _vm.formData.useNotice,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "useNotice", $$v)
                  },
                  expression: "formData.useNotice"
                }
              })
            ],
            1
          ),
          _vm.skuType == 2
            ? _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "商品关联", prop: "selected" } },
                    [
                      _c("el-transfer", {
                        attrs: {
                          filterable: "",
                          "filter-method": _vm.queryShops,
                          titles: ["全部店铺", "关联店铺"],
                          "filter-placeholder": "请输入店铺名",
                          data: _vm.shops
                        },
                        model: {
                          value: _vm.formData.selected,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "selected", $$v)
                          },
                          expression: "formData.selected"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            [
              _c("el-button", { on: { click: _vm.cancle } }, [_vm._v("取 消")]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.doUpdate } },
                [_vm._v("更 新")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }