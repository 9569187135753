import service from '@/utils/request'; // @Tags ResourceSku
// @Summary 创建EquitySku
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.ResourceSku true "创建EquitySku"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /equitySkus/createEquitySku [post]

export var createEquitySku = function createEquitySku(data) {
  data.salePrice = parseFloat(data.salePrice);
  data.facePrice = parseFloat(data.facePrice);
  data.scCostPrice = parseFloat(data.scCostPrice);
  data.costPrice = parseFloat(data.costPrice);
  return service({
    url: "/equitySkus/createEquitySku",
    method: 'post',
    data: data
  });
};
export var exportSku = function exportSku(data) {
  return service({
    url: "/equitySkus/export",
    method: 'post',
    data: data
  });
}; // @Tags ResourceSku
// @Summary 删除EquitySku
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.ResourceSku true "删除EquitySku"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /equitySkus/deleteEquitySku [delete]

export var deleteEquitySku = function deleteEquitySku(data) {
  return service({
    url: "/equitySkus/deleteEquitySku",
    method: 'delete',
    data: data
  });
}; // @Tags ResourceSku
// @Summary 删除EquitySku
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.IdsReq true "批量删除EquitySku"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /equitySkus/deleteEquitySku [delete]

export var deleteEquitySkuByIds = function deleteEquitySkuByIds(data) {
  return service({
    url: "/equitySkus/deleteEquitySkuByIds",
    method: 'delete',
    data: data
  });
}; // @Tags ResourceSku
// @Summary 更新EquitySku
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.ResourceSku true "更新EquitySku"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"更新成功"}"
// @Router /equitySkus/updateEquitySku [put]

export var updateEquitySku = function updateEquitySku(data) {
  data.salePrice = parseFloat(data.salePrice);
  data.facePrice = parseFloat(data.facePrice);
  data.scCostPrice = parseFloat(data.scCostPrice);
  data.costPrice = parseFloat(data.costPrice);
  return service({
    url: "/equitySkus/updateEquitySku",
    method: 'put',
    data: data
  });
}; // @Tags ResourceSku
// @Summary 用id查询EquitySku
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.ResourceSku true "用id查询EquitySku"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"查询成功"}"
// @Router /equitySkus/findEquitySku [get]

export var findEquitySku = function findEquitySku(params) {
  return service({
    url: "/equitySkus/findEquitySku",
    method: 'get',
    params: params
  });
}; // @Tags ResourceSku
// @Summary 分页获取EquitySku列表
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.PageInfo true "分页获取EquitySku列表"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /equitySkus/getEquitySkuList [get]

export var getEquitySkuList = function getEquitySkuList(params) {
  return service({
    url: "/equitySkus/getEquitySkuList",
    method: 'get',
    params: params
  });
};
export var downSaleEquitySku = function downSaleEquitySku(params) {
  return service({
    url: "/equitySkus/downSaleEquitySku",
    method: 'get',
    params: params
  });
};
export var addEquitySkuStock = function addEquitySkuStock(data) {
  return service({
    url: "/equitySkus/addEquitySkuStock",
    method: 'post',
    data: data
  });
};
export var onSaleEquitySku = function onSaleEquitySku(params) {
  return service({
    url: "/equitySkus/onSaleEquitySku",
    method: 'get',
    params: params
  });
};